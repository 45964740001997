import React from 'react';
import {Col, Row} from 'react-bootstrap'

const Crew = () =>
  <div>
    <Col xs={1} md={2} />
    <Col xs={10} md={8} >
      <Col xs={12} md={6} >
        <div style={{marginTop:"50px"}}/>
        <h3>Director</h3>
        <h3>Rick Walters</h3>
        <p>
          Rick Walters is a 14-year US Army veteran with a creative mind and an eye for composition. 
          As a photographer and a videographer, he enjoys telling stories with framing, color and movement 
          while paying strict attention to technical specificity. He began working professionally in 2010 
          for the veteran owned and run Adventus Films on narrative film, documentary and commercial 
          projects. He’s produced award winning short films like A Man Buried, Scamp and the feature, 
          film, Fantastic Confabulations (Old Loyal Films). Currently he is co-directing the post-apocalyptic, 
          sci-fi feature film Phoenix Run (JWalk Entertainment). Rick is relentless in pursuing his craft 
          and said that he makes film because he enjoys solving complex problems with stimulating people. 
          Rick is a professional actor as well and has made appearances on TNT’s Leverage and a recurring 
          role on NBC- Universal’s Grimm. His next personal project is a veteran outreach program called 
          The Warrior Film Project, designed to provide production service and support to veterans who 
          want to become filmmakers and more importantly, to find healing through the art of film making. 
          Currently, Rick is a union Location scout in New York and New Jersey, and has enjoyed working on 
          television shows Power, Billions, Blacklist, Iron Fist, Mozart In The Jungle, The Enemy Within 
          and Little America.
        </p>
      </Col>
      <Col xs={12} md={6} >
        <div style={{marginTop:"50px"}}/>
        <h3>Producer</h3>
        <h3>Heather Pilder Olson</h3>
        <p>
          Heather has written, produced and directed several award-winning films, including the shorts 
          HAUNTED WHARF: ETHEL AND THE MERMAN, DATE STORIES, and was a producer for PEARL. She was an associate producer 
          for the documentary GOLD BALLS, which profiles US tennis players who are all over the age of 80 
          as they compete for the US national title. GOLD BALLS premiered at the Seattle International 
          Film Festival in 2016, and has screened at 16 other festivals in the US and abroad. GOLD BALLS 
          is slated for PBS television in 2019. She has written the teleplay CRACK THE WHIP, and feature 
          screenplays 38 MINUTES, DODGERS and BIRTH. She has a large love of comedy and macaroni and 
          cheese.
        </p>
        <a href={"http://www.heatherpilderolson.com"}>www.heatherpilderolson.com</a>
      </Col>
      <Col xs={12} md={6} >
        <div style={{marginTop:"50px"}}/>
        <h3>Additional Crew</h3>
        <Row>
          <Col xs={6} md={6} lg={3} ><div>Producer</div></Col>
          <Col xs={6} md={6} lg={3} ><div>Andrew Tribolini</div></Col>
        </Row>
        <div style={{marginTop:"20px"}}/>
        <Row>
          <Col xs={6} md={6} lg={3} ><div>Producer</div></Col>
          <Col xs={6} md={6} lg={3} ><div>TJ Walker</div></Col>
        </Row>
        <div style={{marginTop:"20px"}}/>
        <Row>
          <Col xs={6} md={6} lg={3} ><div>Lead Camera Operator</div></Col>
          <Col xs={6} md={6} lg={3} ><div>Dan McComb</div></Col>
        </Row>
        <div style={{marginTop:"20px"}}/>
        <Row>
          <Col xs={6} md={6} lg={3} ><div>Assistant Camera</div></Col>
          <Col xs={6} md={6} lg={3} ><div>Jaron Olson</div></Col>
        </Row>
        <div style={{marginTop:"20px"}}/>
        <Row>
          <Col xs={6} md={6} lg={3} ><div>Drone Operator</div></Col>
          <Col xs={6} md={6} lg={3} ><div>Mick Mason</div></Col>
        </Row>
        <div style={{marginTop:"20px"}}/>
        <Row>
          <Col xs={6} md={6} lg={3} ><div>Sound</div></Col>
          <Col xs={6} md={6} lg={3} ><div>Carli Plute</div></Col>
        </Row>
        <div style={{marginTop:"20px"}}/>
        <Row>
          <Col xs={6} md={6} lg={3} ><div>Editor</div></Col>
          <Col xs={6} md={6} lg={3} ><div>David Wesley Phillips</div></Col>
        </Row>
        <div style={{marginTop:"50px"}}/>
      </Col>
    </Col>
    <Col xs={1} md={2} />
  </div>

export default Crew;