import React from 'react';
import {Col} from 'react-bootstrap';

const HomePage = () =>
<div>
  <Col xs={1} md={2} />
  <Col xs={10} md={8} >
    <h1>
      Let's get rid of left turns!
    </h1>
    <div style={{marginTop:"50px"}}/>
    <h3>
      Let's clarify what we mean by "left turns"
    </h3>
    <p>
      For the purposes of this website "left turns" are turns that require the driver of a motor vehicle
      to make a turn across a lane of on-coming traffic in a densely populated area.
    </p>
    <p>
      In the UK, Australia and a number of other countries this description would refer to right turns.
      However, for the time being, this website will over-simplify the concept as "left turns". We were 
      happy the domain nomoreleftturns.com was available. Maybe we will figure out a better name in the 
      future, maybe not. 
    </p>
    <div style={{marginTop:"50px"}}/>
    <h3>
      A note about population density
    </h3>
    <p>
      The arguments presented below are really mostly relevant in densely populated areas - areas with
      moderate to heavy traffic.  It is assumed that with moderate to heavy traffic there is also a
      corresponding increase in pedestrian traffic. 
    </p>
    <div style={{marginTop:"50px"}}/>
    <h3>
      Why do we want to eliminate left turns?
    </h3>
    <p><b>Left turns are annoying</b></p>
    <p>
      Left turns significantly reduce the flow of traffic.
    </p>
    <p><b>Left turns are dangerous</b></p>
    <p>
      Left turns increase the risk of physical harm to any persons or objects in the vicinty of the
      intersection. This includes other vehicles and drivers, objects near the intersection and in 
      particular pedestrian and bicyclists - for whom the risks include serious physical harm or even 
      death.
    </p>
    <p><b>Left turns have an economic cost</b></p>
    <p>
      As a direct result of the reduction in traffic flow left turns increase the economic cost of driving
      for both individuals and businesses.
    </p>
    <p><b>Left turns have an environmental cost</b></p>
    <p>
      As a direct result of the reduction in traffic flow, left turns also increase the use of fossil fuels
      and the release of carbon emmissions which is detrimental the air we breath and the planet we live on.
    </p>
  </Col>
  <Col xs={1} md={2} />
</div>;

export default HomePage;
